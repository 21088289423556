body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label {
  font-size: xx-small;
}

.mainarea {
  overflow: auto;
  display: grid;
  grid-template-rows: auto atuo;
  grid-template-columns: 50% 50%;
}

.teama {
  grid-row: 2;
  grid-column: 2;
}

.teamb {
  grid-row: 2;
  grid-column: 1;
}

.rink {
  grid-row: 1;
  grid-column: 1 / span 2;
}

#rinkCanvas {
  margin: 4px;
  background-color: whitesmoke;
}

.teamInfo {
  display: block;
  overflow: auto;
  margin: 4px;
  border: solid 1px whitesmoke;
}

.teamInfoTable {
  font-size: x-small;
  border-collapse: collapse;
  width: 100%;
}

.teamInfoTable td {
  border: solid 1px grey;
}
